// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top__content {
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .logo img {
    width: 60%;
  }
}

.logout {
  font-size: 20px;
  font-weight: 400;
  color: rgb(7, 7, 7);
  cursor: pointer;
}
@media (max-width: 480px) {
  .logout {
    font-size: 16px;
  }
}
.logout:hover {
  color: #991e1e;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.styles.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,aAAA;EACA,8BAAA;AADJ;;AAKI;EACI;IACI,UAAA;EAFV;AACF;;AAMA;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAII;EALJ;IAMQ,eAAA;EADN;AACF;AAEI;EACI,cAAA;AAAR","sourcesContent":["\n\n.top__content {\n    padding-top: 25px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.logo {\n    @media(max-width: 480px) {\n        img {\n            width: 60%;\n        }\n    }\n}\n\n.logout {\n    font-size: 20px;\n    font-weight: 400;\n    color: rgb(7,7,7);\n    cursor: pointer;\n    @media(max-width: 480px) {\n        font-size: 16px;\n    }\n    &:hover {\n        color:#991e1e\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
