import React from 'react';
import Logo from '../components/Logo/Logo';
import HomeContent from '../components/HomeContent/HomeContent';

function WorkingPage() {
  return (
    <>
      <Logo />
      <HomeContent />
    </>
  );
}

export default WorkingPage;
