// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-brigade__filter {
  margin-top: 15px;
  display: flex;
}
.calendar-brigade__filter-item {
  font-size: 19px;
  font-weight: 500;
  color: rgb(7, 7, 7);
  padding-right: 10px;
  cursor: pointer;
}
.calendar-brigade__filter-item:active {
  color: #ff0000;
}
.calendar-brigade__filter-item.active {
  color: #ff0000;
}
.calendar-brigade__table {
  margin-top: 25px;
  width: 50%;
}
@media (max-width: 460px) {
  .calendar-brigade__table {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ChangeBrigade/style.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,aAAA;AADR;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AADZ;AAEY;EACI,cAAA;AAAhB;AAGY;EACI,cAAA;AADhB;AAMI;EACI,gBAAA;EACA,UAAA;AAJR;AAKQ;EAHJ;IAIQ,WAAA;EAFV;AACF","sourcesContent":[".calendar-brigade {\n   \n    &__filter {\n        margin-top: 15px;\n        display: flex;\n\n        &-item {\n            font-size: 19px;\n            font-weight: 500;\n            color: rgb(7,7,7);\n            padding-right: 10px;\n            cursor: pointer;\n            &:active {\n                color: #ff0000;\n               \n              }\n            &.active {\n                color: #ff0000;\n            }\n        }\n    }\n\n    &__table {\n        margin-top: 25px;\n        width: 50%;\n        @media(max-width: 460px) {\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
